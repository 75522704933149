<template>
	<div>
    <v-btn 
      @click="handleExport" 
      color="primary"
      class="ma-2 white--text"
      :loading="loading"
      :disabled="loading"
      elevation="0"
    >
      Exportar a excel
      <v-icon right>
        mdi-file-excel
      </v-icon>
    </v-btn>
  </div>
</template>
<script>
import * as XLSX from "xlsx"
import api from '@/services/api'
import utils from '@/services/utils'
export default {
	props: {
		workspaceID: {
			type: String,
			required: true,
		},
		contentTypeDataID: {
			type: String,
			required: true,
		},
    contentTypeData: {
      type: Object,
      required: true,
    },
	},
	data: () => ({
    loading: false,
		values: null,
    languages: null,
	}),
	computed: {
    /*
    languages () {
      return ['en', 'es', 'it', 'de ', 'pt', 'fr']
    },*/
		debug () {
      if (process.env.VUE_APP_ENV === 'development') return true
			return this.$route.query.debug && this.$route.query.debug.length > 0
		},
    weekDay () {
      return [
        'Lunes',
        'Martes',
        'Mi�rcoles',
        'Jueves',
        'Viernes',
        'S�bado',
        'Domingo',
      ]
    },
		customStyle () {
			let result = ''
			for (const row of this.rows){
				const color = row.color ? row.color : '#FFFFFF'
				result += `
										<Style ss:ID="row${row.id}">
              				<Alignment ss:Vertical="Top" />
											<Interior ss:Color="${color}" ss:Pattern="Solid"></Interior>
              				<Borders>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Right"/>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Left"/>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Top"/>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Bottom"/>
              				</Borders>
											<Font ss:Color="${utils.getOppositeColor(color)}"></Font>
              			</Style>
									`
			}
			return result
		},
	},
  methods: {
    getTranslation(v, locale, autoCompleteIfNull) {
      if (autoCompleteIfNull) return utils.getTranslation(v, locale)
      return v ? v[locale] : null
    },
    prepareDate (v) {
      if (!v) return
      const date = new Date(v)
      return `${utils.pad(date.getDate())}/${utils.pad((date.getMonth() + 1))}/${date.getFullYear()}`
    },
    convertTime (v) {
      if (!v) return '00:00'

      const h = v / 60 | 0
      const m = v - (h * 60)
      return `${h} : ${utils.pad(m)}`
    },
		prepareData (v) {
			const result = []
      for (const event of v) {
        const aux = {}

        //aux['D�a'] = event.Date	
        aux['D�a'] = this.prepareDate(event.Date)
        aux['Hora Inicio'] = event.Time ? this.convertTime(event.Time).split(' ').join('') : null
        aux['Hora Fin'] = event.TimeEnd ? this.convertTime(event.TimeEnd).split(' ').join('') : null
        for (const l of this.languages) {
          aux[`Actividad (${l})`] = this.getTranslation(JSON.parse(event.Title), l, true)
        }
        
        aux[`Locacion`] = this.getTranslation(JSON.parse(event.Place), 'es')

        aux['ID'] = event.ID
        aux['Imagen'] = event.Image
        
        for (const l of this.languages) {
          aux[`Descripci�n (${l})`] = event.Description ? this.getTranslation(JSON.parse(event.Description), l, true) : ''
        }
        aux[`Reservable`] = event.Reservable ? 'reservable' : null
        aux[`PVP alojado`] = event.Reservable ? event.PVPHosted : null
        aux[`Cupos`] = event.Reservable ? event.PAX : null
        //aux[`PVP no alojado`] = event.Reservable ? event.PVPNoHosted : null
        /*
        for (const l of this.languages) {
          aux[`Ubicaci�n (${l})`] = this.getTranslation(JSON.parse(event.Place), l)
        }
        */
        result.push(aux)
      }

			return result
		},
		getValue (weekID, mealID, rowID, optionID, day) {
			const aux = this.values.filter(x => 
                  x.Week === weekID &&
                  x.Row === rowID &&
                  x.Meal === mealID &&
                  x.Day === day.toString() &&
                  (!optionID || x.Option === optionID)
                ).map(item => item.Value).shift()
      return aux ? aux : ''
		},
		async handleExport () {
      this.loading = true
      this.languages = await new Promise((resolve, reject) => api.getItem ('dviewcontent', 'v1/public/workspaces/', this.workspaceID)
          .then(response => {   
            resolve(response.Languages ? JSON.parse(response.Languages) : [])
          })
        .catch (err => {
          reject(err)
        }))
      const url = `v1/public/activity-barcelo/${this.contentTypeDataID}/excel`
      api.getAll ('dviewcontent', url, 0, 1000, '')
        .then(response => {
          if (this.contentTypeData.Show) {
            this.handleCreateExcel(`Calendario - Show.xls`, `Show`, response)
          } else {
            for (const category of this.contentTypeData.Categories) {
              this.handleCreateExcel(`Calendario - ${this.getTranslation(category.Name, 'es')}.xls`, `${this.getTranslation(category.Name, 'es')}`, response.filter(x => x.CategoryID === category.ID))
            }
          }
          this.loading = false
        })
        .catch (error => {
          console.log(error)
          this.loading = false
        })
      /*
      console.log(this.contentTypeData)
      this.loading = false
      */
    },
    handleCreateExcel (fileName, pageName, data) {
			/*const data = [{
				 firstName: 'John',
				 lastName: 'Doe'
				}, {
				 firstName: 'Smith',
				 lastName: 'Peters'
				}, {
				 firstName: 'Alice',
				 lastName: 'Lee'
				}]
*/
			/*
			const workSheet = XLSX.utils.aoa_to_sheet(data);
  // Generate a Work Book containing the above sheet.
  const workBook = {
    Sheets: { data: workSheet, cols: [] },
    SheetNames: ["data", 'data2'],
  };
	*/

      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
      const wb = XLSX.utils.book_new()

      const ws = XLSX.utils.json_to_sheet(this.prepareData(data))
      XLSX.utils.book_append_sheet(wb, ws, pageName)
      /*
			for (const week of this.weeks) {
				this.prepareData(week)
				const ws = XLSX.utils.json_to_sheet(this.prepareData(week))
				XLSX.utils.book_append_sheet(wb, ws, week.name.replace(' (actual)', ''))
			}
      */

			const excelBuffer = XLSX.write(wb, { bookType: "xls", type: "array" });
			//const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });

      // Exporting the file with the desired name and extension.
      //const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });


			const fileData = new Blob([excelBuffer], { type: fileType });
			var link = document.createElement("A")
			link.href = URL.createObjectURL(fileData)
			link.download = fileName
			//link.download = 'export.csv'
			link.target = '_blank'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			
			/*
			const items = [
          { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
          { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
          { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
          { age: 38, first_name: 'Jami', last_name: 'Carney' }
        ]
      console.log(XLSX)
			const data = XLSX.utils.json_to_sheet(this.items)
			const wb = XLSX.utils.book_new()
			XLSX.utils.book_append_sheet(wb, data, 'data')
			XLSX.writeFile(wb,'demo.xlsx')
	*/
		},
    handleGetData () {
      /*
      api.getAllWithoutLimit ('dviewcontent', `v1/private/weekly-menu/${this.contentTypeDataID}`)
        .then((response) => {   
					this.values = response
        })
      */
    },
  },
}
</script>


